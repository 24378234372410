import { toast } from 'react-toastify';

export const handleResendVerificationCodeErrors = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 404:
                toast.error('Aucun utilisateur trouvé avec cette adresse email.');
                break;
            case 422:
                toast.error('Cet email est déjà vérifié.');
                break;
            default:
                toast.error('Une erreur est survenue. Veuillez réessayer.');
                break;
        }
    } else {
        toast.error('Une erreur est survenue. Veuillez réessayer.');
    }
};

export const handleResendVerificationCodeSuccess = () => {
    toast.success('Un nouveau code de vérification a été envoyé à votre adresse email.');
};
