import React, { useState } from 'react';

const SearchByFilter = () => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <div className="bg-white p-4 shadow-md">
      {/* Title */}
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Coiffeurs</h1>

      {/* Filter Section */}
      <div className="flex justify-between items-center">
        {/* Left Side: Filters */}
        <div className="flex space-x-4">
          {/* Dropdown for Coiffeurs */}
          <select className="px-4 py-2 border rounded-md bg-gray-100">
            <option>Coiffeurs</option>
          </select>

          {/* Dropdown for Categories */}
          <select className="px-4 py-2 border rounded-md bg-gray-100">
            <option>Catégories</option>
          </select>

          {/* Dropdown for Location */}
          <select className="px-4 py-2 border rounded-md bg-gray-100">
            <option>Choisir une localisation</option>
          </select>

          {/* Gift Button */}
          <button className="px-4 py-2 border rounded-md bg-gray-100 flex items-center">
            🎁 Bon Cadeau
          </button>
        </div>

        {/* Right Side: Toggle */}
        <div className="flex items-center space-x-2">
          <span className="text-gray-600">Afficher la carte</span>

          {/* Toggle Switch */}
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only"
              checked={isToggled}
              onChange={() => setIsToggled(!isToggled)}
            />
            <div className={`w-11 h-6 ${isToggled ? 'bg-teal-500' : 'bg-gray-200'} rounded-full peer-focus:ring-4 peer-focus:ring-blue-300 dark:bg-gray-700 transition-colors`}>
              <div
                className={`absolute top-[2px] left-[2px] w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${isToggled ? 'translate-x-full' : ''}`}
              ></div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default SearchByFilter;
