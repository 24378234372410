import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../helpers/authContext';
import { BASE_URL } from '../helpers/url';

const DetailsProfile = () => {
    // État pour stocker les données du profil
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: '',
    });

    // Récupérer accessToken depuis AuthContext
    const { accessToken } = useContext(AuthContext);

    useEffect(() => {
        // Fonction pour récupérer les données utilisateur depuis l'API
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/profile`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Envoyer le token d'accès dans l'en-tête
                    },
                });

                // Stocker les données récupérées dans l'état userData
                setUserData({
                    first_name: response.data.first_name || '',
                    last_name: response.data.last_name || '',
                    email: response.data.email || '',
                    phone: response.data.phone || '',
                    gender: response.data.gender || '',
                });
            } catch (error) {
                console.error('Erreur lors du chargement des données utilisateur:', error);
            }
        };

        fetchUserData();
    }, [accessToken]); // Exécuter lorsque le token change

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            {/* Section des détails de contact */}
            <div className="border border-gray-200 rounded-lg mb-6">
                <div className="p-4 border-b border-gray-200 bg-gray-100">
                    <h2 className="text-lg font-bold text-gray-700">DÉTAILS DE CONTACT</h2>
                </div>
                <div className="p-4">
                    <div className="mb-4">
                        <p className="text-gray-600 font-semibold">Email:</p>
                        <p className="text-gray-900">{userData.email}</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-600 font-semibold">Téléphone:</p>
                        <p className="text-gray-900">{userData.phone}</p>
                    </div>
                    <div className="mb-4">
                        <p className="text-gray-600 font-semibold">Genre:</p>
                        <p className="text-gray-900">{userData.gender}</p>
                    </div>
                </div>
            </div>

            {/* Section "À propos de moi" */}
            <div className="border border-gray-200 rounded-lg">
                <div className="p-4 border-b border-gray-200 bg-gray-100">
                    <h2 className="text-lg font-bold text-gray-700">À PROPOS DE MOI</h2>
                </div>
                <div className="p-4">
                    <p className="text-gray-600 font-semibold">Nom:</p>
                    <p className="text-gray-900">{`${userData.first_name} ${userData.last_name}`}</p>
                </div>
            </div>
        </div>
    );
};

export default DetailsProfile;
