import React from 'react';

import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';
import AppointmentCalendar from '../components/AppointmentCalendar';


export default function HomePageOne() {
  return (
    <>
      {/* TopHeader */}
      <TopHeader />

      {/* AppointmentCalendar */}
      <AppointmentCalendar />

      {/* Footer */}
      {/* <Footer /> */}
    </>
  );
}
