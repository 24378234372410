import React from 'react';
import HeaderOne from "../components/HeaderOne";
import Categories from "../components/Categories";
import Statistics from '../components/Statistics';
import HowItWorks from '../components/HowItWorks';
import SocialMedia from '../components/SocialMedia';
import Testimonial from '../components/Testimonial';
import PopularCities from '../components/PopularCities';
import Footer from '../components/Footer';


export default function HomePageOne() {
  return (
    <>
      {/* HeaderOne */}
      <HeaderOne />

      {/* Categories */}
      <Categories />

      {/* Statistics */}
      <Statistics />

      {/* HowItWorks */}
      <HowItWorks />

      {/* SocialMedia */}
      <SocialMedia />

      {/* Testimonial */}
      <Testimonial />

      {/* PopularCities */}
      <PopularCities />

      {/* Footer */}
      <Footer />
    </>
  );
}
