import { toast } from 'react-toastify';

export const handleVerifyErrors = (error) => {
    if (error.response) {
        const status = error.response.status;
        const message = error.response.data.message || error.response.data.error || 'Une erreur est survenue. Veuillez réessayer.';

        switch (status) {
            case 400:
                if (message === 'Aucune session utilisateur active.') {
                    toast.error('Aucune session utilisateur active.', {
                        position: "top-right"
                    });
                } else if (message === 'Code de vérification expiré.') {
                    toast.error('Le code de vérification a expiré.', {
                        position: "top-right"
                    });
                } else {
                    toast.error(message, {
                        position: "top-right"
                    });
                }
                break;
            case 401:
                if (message === 'Code de vérification expiré.') {
                    toast.error('Le code de vérification a expiré.', {
                        position: "top-right"
                    });
                } else {
                    toast.error(message, {
                        position: "top-right"
                    });
                }
                break;
            case 404:
                toast.error('Aucun utilisateur trouvé avec cette adresse email.', {
                    position: "top-right"
                });
                break;
            case 402:
                toast.error('Le code de confirmation est incorrect.', {
                    position: "top-right"
                });
                break;
            default:
                toast.error('Une erreur est survenue. Veuillez réessayer.', {
                    position: "top-right"
                });
                break;
        }
    } else {
        toast.error('Une erreur est survenue. Veuillez réessayer.', {
            position: "top-right"
        });
    }
};
