import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../helpers/url';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(() => {
    // Charger l'accessToken depuis localStorage s'il existe
    return localStorage.getItem('accessToken') || null;
  });

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Sauvegarder l'accessToken dans localStorage à chaque fois qu'il change
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      fetchUserProfile(accessToken); // Charger le profil utilisateur lorsqu'un accessToken est présent
    } else {
      localStorage.removeItem('accessToken');
      setUser(null); // Réinitialiser l'utilisateur si le token est supprimé
    }
  }, [accessToken]);

  const fetchUserProfile = async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setUser(response.data); // Mettre à jour les informations de l'utilisateur
    } catch (error) {
      console.error('Erreur lors de la récupération du profil utilisateur :', error);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Fonction pour utiliser le contexte d'authentification
export const useAuth = () => {
  return useContext(AuthContext);
};
