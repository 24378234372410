import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../helpers/authContext';
import { BASE_URL } from '../helpers/url';

const TopHeader = () => {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, user, setUser } = useContext(AuthContext);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const userDropdownRef = useRef(null);
  const languageDropdownRef = useRef(null);

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    setIsLanguageDropdownOpen(false);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
    setIsUserDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
      setIsUserDropdownOpen(false);
    }
    if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
      setIsLanguageDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    navigate('/');
  };

  const getConfig = (token) => ({
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const handleLogoutClick = async () => {
    try {
      const config = getConfig(accessToken);
      await axios.post(`${BASE_URL}/logout`, {}, config);
      localStorage.removeItem('accessToken');
      setAccessToken(null);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (accessToken) {
          const config = getConfig(accessToken);
          const response = await axios.get(`${BASE_URL}/profile`, config);
          setUser(response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du profil :', error.response ? error.response.data : error.message);
      }
    };
    fetchProfile();
  }, [accessToken, setUser]);

  return (
    <header className="bg-[rgb(251,127,127)] h-[56px] flex justify-between items-center px-6">
      <div className="text-white font-semibold text-lg cursor-pointer" onClick={handleLogoClick}>
        Best Salons
      </div>

      <div className="flex items-center space-x-4">
        {user ? (
          <div className="relative" ref={userDropdownRef}>
            <button onClick={toggleUserDropdown} className="text-white text-sm font-medium">
              {user.first_name}
            </button>
            {isUserDropdownOpen && (
              <div className="absolute right-0 mt-2 bg-white text-gray-900 rounded shadow-lg w-48">
                <ul className="p-2">
                  <li
                    className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                    onClick={() => navigate('/profile')}
                  >
                    Profil
                  </li>
                  <li
                    className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                    onClick={() => navigate('/update-profile')}
                  >
                    Modifier Profil
                  </li>
                  <li
                    className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                    onClick={handleLogoutClick}
                  >
                    Déconnexion
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <button className="text-white" onClick={() => navigate('/login')}>
            Connexion
          </button>
        )}

        <div className="relative" ref={languageDropdownRef}>
          <button onClick={toggleLanguageDropdown} className="text-white text-sm font-medium">
            FR ▾
          </button>
          {isLanguageDropdownOpen && (
            <div className="absolute right-0 mt-2 bg-white text-gray-900 rounded shadow-lg">
              <ul className="p-2">
                <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Français</li>
                <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Nederlands</li>
                <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Deutsch</li>
                <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">English</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default TopHeader;
