import React from 'react';

const Statistics = () => {
  return (
    <div className="bg-gray-100 py-8 flex items-center" style={{ minHeight: '200px' }}>
      <div className="max-w-7xl mx-auto flex justify-around text-center w-full">
        {/* First Stat */}
        <div className="border-r border-gray-300 px-4">
          <h3 className="text-3xl font-bold" style={{ color: 'rgb(251 127 127)' }}>17.609.002</h3>
          <p className="text-gray-600">Réservations gérées par notre calendrier intelligent</p>
        </div>

        {/* Second Stat */}
        <div className="border-r border-gray-300 px-4">
          <h3 className="text-3xl font-bold" style={{ color: 'rgb(251 127 127)' }}>4.703.069</h3>
          <p className="text-gray-600">Réservations prises en ligne</p>
        </div>

        {/* Third Stat */}
        <div className="px-4">
          <h3 className="text-3xl font-bold" style={{ color: 'rgb(251 127 127)' }}>9.167.356</h3>
          <p className="text-gray-600">SMS de rappel envoyés</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
