import React from 'react';

const HowItWorks = () => {
  return (
    <div className="max-w-7xl mx-auto py-16">
      {/* Title */}
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold" style={{ color: 'rgb(251 127 127)' }}>COMMENT</h2>
        <h2 className="text-3xl font-bold" style={{ color: 'rgb(251 127 127)' }}>ÇA FONCTIONNE?</h2>
      </div>

      {/* Steps */}
      <div className="flex justify-between items-start">
        {/* Step 1 */}
        <div className="flex flex-col items-center">
          <div className="text-5xl font-bold" style={{ color: 'rgb(251 127 127)' }}>1</div>
          <img src="image1_url" alt="Step 1" className="mb-4" />
          <h3 className="text-xl font-semibold mb-2">Créez votre profil Best Salon</h3>
          <p className="text-gray-600 text-center mb-4">
            Créez votre compte en toute simplicité grâce à la connexion Facebook ou à l’aide de votre email et numéro de téléphone.
          </p>
          <button className="bg-red-500 text-white px-4 py-2 rounded">S'inscrire maintenant</button>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center">
          <div className="text-5xl font-bold" style={{ color: 'rgb(251 127 127)' }}>2</div>
          <img src="image2_url" alt="Step 2" className="mb-4" />
          <h3 className="text-xl font-semibold mb-2">Choisissez votre salon et vos prestations</h3>
          <p className="text-gray-600 text-center mb-4">
            Envie d’une manucure ? Une nouvelle coupe de cheveux ? Best Salon vous simplifie la vie !
          </p>
          <button className="bg-red-500 text-white px-4 py-2 rounded">Trouvez votre salon ou institut préféré</button>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center">
          <div className="text-5xl font-bold" style={{ color: 'rgb(251 127 127)' }}>3</div>
          <img src="image3_url" alt="Step 3" className="mb-4" />
          <h3 className="text-xl font-semibold mb-2">Réservez la date et l'heure de votre choix</h3>
          <p className="text-gray-600 text-center mb-4">
            Vérifiez la disponibilité en ligne et réservez immédiatement, de n'importe où et à n'importe quel moment.
          </p>
          <button className="bg-red-500 text-white px-4 py-2 rounded">Réserver maintenant</button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
