import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../helpers/authContext'; // Contexte d'authentification
import axios from 'axios'; // Importer Axios
import { BASE_URL } from '../helpers/url'; // URL de base

const HeaderOne = () => {
    const { accessToken, setAccessToken } = useAuth();
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
    const [user, setUser] = useState(null); // Utilisateur connecté
    const userDropdownRef = useRef(null);
    const languageDropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
        setIsLanguageDropdownOpen(false); // Fermer le dropdown de langue lorsque celui de l'utilisateur est ouvert
    };

    const toggleLanguageDropdown = () => {
        setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
        setIsUserDropdownOpen(false); // Fermer le dropdown d'utilisateur lorsque celui de langue est ouvert
    };

    const handleClickOutside = (event) => {
        if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
            setIsUserDropdownOpen(false);
        }
        if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
            setIsLanguageDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleLoginClick = () => {
        navigate('/login');
    };

    const getConfig = (token) => {
        return {
            headers: {
                Authorization: `Bearer ${token}`, // Inclure le jeton dans l'en-tête
                'Content-Type': 'application/json',
            },
        };
    };

    const handleLogoutClick = async () => {
        try {
            const config = getConfig(accessToken); // Récupérer les en-têtes avec le jeton
            await axios.post(`${BASE_URL}/logout`, {}, config); // Envoyer la requête de déconnexion

            localStorage.removeItem('accessToken'); // Supprimer le jeton du localStorage
            setAccessToken(null); // Réinitialiser l'état du jeton
            navigate('/'); // Rediriger vers la page d'accueil après la déconnexion
        } catch (error) {
            console.error("Erreur lors de la déconnexion :", error.response ? error.response.data : error.message);
            alert("Erreur lors de la déconnexion. Veuillez réessayer.");
        }
    };

    // Récupérer les informations du profil utilisateur
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                if (accessToken) {
                    const config = getConfig(accessToken);
                    const response = await axios.get(`${BASE_URL}/profile`, config); // Récupérer le profil
                    setUser(response.data); // Stocker les données de l'utilisateur
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du profil :", error.response ? error.response.data : error.message);
            }
        };
        fetchProfile();
    }, [accessToken]);

    // Effect pour surveiller la déconnexion (accessToken supprimé)
    useEffect(() => {
        if (!accessToken) {
            setUser(null);  // Réinitialiser l'utilisateur si le token est supprimé
        }
    }, [accessToken]);

    return (
        <div>
            {/* Partie Header */}
            <div className="absolute top-0 left-0 w-full bg-gray-900 bg-opacity-0 flex items-center justify-between p-8 z-10">
                <h1 className="text-white text-3xl font-bold">Best Salons</h1>

                <div className="flex items-center space-x-8">
                    {/* Bouton Ajouter Salon visible pour tous */}
                    <button className="relative flex items-center bg-red-500 text-white px-10 py-3 rounded-full hover:bg-red-600">
                        <div className="icon-circle absolute -left-2 ml-4 flex justify-center items-center w-8 h-8 rounded-full" style={{ backgroundColor: '#BB3932' }}>
                            <i className="fas fa-plus"></i> {/* Icône "+" */}
                        </div>
                        <span className="ml-8">Ajouter Salon</span>
                    </button>

                    {/* Afficher le bouton Connexion uniquement si l'utilisateur n'est pas connecté */}
                    {!user ? (
                        <button
                            className="relative flex items-center justify-center bg-red-500 text-white px-10 py-3 rounded-full hover:bg-red-600"
                            onClick={handleLoginClick}
                        >
                            <span>Connexion</span>
                        </button>
                    ) : (
                        <div className="relative" ref={userDropdownRef}>
                            <button
                                onClick={toggleUserDropdown}
                                className="text-white text-sm font-medium"
                            >
                                {user.first_name} {/* Affichage du nom de l'utilisateur */}
                            </button>

                            {isUserDropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-white text-gray-900 rounded shadow-lg w-48"> {/* Ajout de w-48 pour augmenter la largeur */}
                                    <ul className="p-2">
                                        <li
                                            className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                                            onClick={() => navigate('/profile')} // Ajouter la navigation vers la page Profil
                                        >
                                            Profil
                                        </li>
                                        <li
                                            className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                                            onClick={() => navigate('/update-profile')} // Ajouter la navigation vers Modifier Profil
                                        >
                                            Modifier Profil
                                        </li>
                                        <li
                                            className="hover:bg-gray-200 px-4 py-2 cursor-pointer"
                                            onClick={handleLogoutClick}
                                        >
                                            Deconnexion
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                    )}

                    {/* Bouton de sélection de langue */}
                    <div className="relative" ref={languageDropdownRef}>
                        <button
                            onClick={toggleLanguageDropdown}
                            className="text-white text-sm font-medium"
                        >
                            FR ▾
                        </button>

                        {isLanguageDropdownOpen && (
                            <div className="absolute right-0 mt-2 bg-white text-gray-900 rounded shadow-lg">
                                <ul className="p-2">
                                    <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Français</li>
                                    <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Nederlands</li>
                                    <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">Deutsch</li>
                                    <li className="hover:bg-gray-200 px-4 py-2 cursor-pointer">English</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Conteneur de l'image de fond */}
            <div className="relative w-full bg-cover bg-center"
                style={{
                    backgroundImage: 'url(https://images.unsplash.com/photo-1634449571010-02389ed0f9b0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                    maxHeight: '700px',
                    minHeight: '700px',
                }}
            >
                {/* Barre de recherche positionnée en absolute */}
                <div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 w-full max-w-[850px] p-4 z-50 rounded-lg flex items-center"
                    style={{ backgroundColor: 'rgb(251 127 127)' }}
                >
                    {/* Input de recherche */}
                    <input
                        type="text"
                        placeholder="Recherche services, salons, instituts"
                        className="w-1/2 bg-transparent outline-none text-white placeholder-white px-2 py-2 border-b border-white"
                    />

                    {/* Icone de recherche */}
                    <button className="text-white px-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-4.35-4.35M17.65 10.95a6.7 6.7 0 11-13.4 0 6.7 6.7 0 0113.4 0z"
                            />
                        </svg>
                    </button>

                    {/* Input de localisation */}
                    <input
                        type="text"
                        placeholder="Où ?"
                        className="w-1/2 bg-transparent outline-none text-white placeholder-white px-2 py-2 border-b border-white ml-4"
                    />

                    {/* Bouton de recherche */}
                    <button className="bg-white text-gray-900 font-bold px-6 py-2 rounded-full ml-4">Rechercher</button>
                </div>
            </div>
        </div>
    );
};

export default HeaderOne;
