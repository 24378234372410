import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-8">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="text-white font-semibold">Société</h3>
            <ul>
              <li><a href="#" className="hover:text-gray-400">Support</a></li>
              <li><a href="#" className="hover:text-gray-400">Équipe & Carrières</a></li>
              <li><a href="#" className="hover:text-gray-400">Référencer votre salon</a></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-white font-semibold">Légal</h3>
            <ul>
              <li><a href="#" className="hover:text-gray-400">Conditions Générales</a></li>
              <li><a href="#" className="hover:text-gray-400">Politique de protection des données</a></li>
              <li>
                <a href="#" className="hover:text-gray-400">
                  Politique relative aux cookies | Préférences
                </a>
              </li>
              <li><a href="#" className="hover:text-gray-400">Mention légale</a></li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-600 mt-8 pt-4 flex flex-col md:flex-row items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="text-gray-400">Pays</span>
            <select className="bg-gray-800 text-gray-300 border border-gray-600 rounded px-2 py-1">
              <option value="fr">France</option>
              <option value="be">Belgique</option>
              <option value="lu">Luxembourg</option>
            </select>
          </div>

          <div className="mt-4 md:mt-0">
            <p className="text-gray-400">© Best Salon S.A. 2024</p>
          </div>

          <div className="mt-4 md:mt-0">
            <a href="#" className="hover:text-gray-400 text-gray-300">Aide</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
