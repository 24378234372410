import React from 'react';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
  const navigate = useNavigate(); // Utilisation du hook useNavigate pour la redirection
  
  const categories = [
    {
      name: 'Coiffeurs',
      image: 'https://plus.unsplash.com/premium_photo-1682090689948-2c8b24f501b7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Barbiers / Barbershops',
      image: 'https://plus.unsplash.com/premium_photo-1677444546740-7092c86ac54d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Massage & Spa',
      image: 'https://plus.unsplash.com/premium_photo-1661328022473-8d8be75571af?q=80&w=2113&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Soins du corps',
      image: 'https://plus.unsplash.com/premium_photo-1664375246438-55b9441b5e3c?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Épilations',
      image: 'https://plus.unsplash.com/premium_photo-1661431392914-e3fc8ff0e51a?q=80&w=1908&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Onglerie et Pédicure',
      image: 'https://plus.unsplash.com/premium_photo-1680348266597-6d89a08d12d7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Maquillage professionnel',
      image: 'https://images.unsplash.com/photo-1487412947147-5cebf100ffc2?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Soin du visage',
      image: 'https://plus.unsplash.com/premium_photo-1661645623435-41d9869758d9?q=80&w=1928&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      name: 'Blanchiment dentaire',
      image: 'https://plus.unsplash.com/premium_photo-1661693473114-07f17f679722?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  ];


  // Fonction pour rediriger l'utilisateur
  const handleCategoryClick = (categoryName) => {
    navigate('/categories', { state: { categoryName } }); // Redirection avec la catégorie en paramètre
  };

  return (
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Rechercher par catégorie</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {categories.map((category, index) => (
          <div
            key={index}
            className="relative bg-white rounded-lg shadow-md overflow-hidden hover:bg-green-100 transition duration-300 group"
            style={{ width: '360px', height: '287px', position: 'relative', cursor: 'pointer' }}
            onClick={() => handleCategoryClick(category.name)} // Gestionnaire de clic pour rediriger
          >
            <img
              src={category.image}
              alt={category.name}
              className="w-full h-full object-cover transition duration-300 group-hover:opacity-50"
            />
            <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
              <h3 className="text-white text-lg font-semibold px-4 py-2 rounded">
                Réserver maintenant
              </h3>
            </div>
            <div
              className="absolute bottom-0 left-0 right-0 text-white p-4 text-center"
              style={{ backgroundColor: 'rgb(251 127 127)' }}
            >
              <h3 className="text-lg font-semibold">{category.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
