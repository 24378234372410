import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../helpers/url';

// Fix default icon issue in Leaflet
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const SalonAdresseMap = () => {
  const location = useLocation();
  const { salonId } = location.state || {}; // Récupérer salonId depuis l'état passé via navigate()

  const [salon, setSalon] = useState(null); // Pour stocker les détails du salon
  const [position, setPosition] = useState([50.8503, 4.3517]); // Par défaut à Bruxelles, remplacez si nécessaire

  useEffect(() => {
    const fetchSalonDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/salon-details/${salonId}`);
        setSalon(response.data.salon);

        // Remplacer la position par les coordonnées si disponibles dans l'API
        console.log("Salon======", response.data.salon);
        if (response.data.salon.coordinates && Array.isArray(response.data.salon.coordinates) && response.data.salon.coordinates.length === 2) {
          setPosition(response.data.salon.coordinates); // Assurez-vous que le format correspond [lat, lng]
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des détails du salon:', error);
      }
    };

    if (salonId) {
      fetchSalonDetails();
    }
  }, [salonId]);

  if (!salon) {
    return <div>Chargement...</div>; // Affichage pendant le chargement
  }

  // Gestion des heures d'ouverture avec un essai de parsing
  let hours = {};
  try {
    // Vérifiez si salon.hours est une chaîne avant de le parser
    if (typeof salon.hours === 'string') {
      hours = JSON.parse(salon.hours);
    } else if (typeof salon.hours === 'object') {
      hours = salon.hours; // Si c'est déjà un objet, utilisez-le directement
    }
  } catch (error) {
    console.error('Erreur lors de l\'analyse des heures:', error);
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      {/* Title */}
      <h2 className="text-2xl font-semibold text-gray-700 mb-6">Informations</h2>

      {/* Map Section */}
      <div className="w-full h-96 mb-8 rounded-lg overflow-hidden">
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} className="h-full">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>
              {salon.name} <br /> {salon.address ? salon.address : 'Adresse non disponible'}
            </Popup>
          </Marker>
        </MapContainer>
      </div>

      {/* Contact and Hours Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Hours of operation */}
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Contact / Heures d'ouverture</h3>
          <ul className="space-y-2 text-gray-600">
            {Object.entries(hours).map(([day, times]) => (
              <li key={day}>
                {day.charAt(0).toUpperCase() + day.slice(1)}:{' '}
                <span className="ml-4">{times.join(', ')}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Address and Contact Info */}
        <div>
          <ul className="space-y-2 text-gray-600">
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">place</span>
              {salon.address ? salon.address : 'Adresse non disponible'}
            </li>
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">phone</span>
              {salon.phone ? salon.phone : 'Numéro de téléphone non disponible'}
            </li>
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">print</span>
              {salon.fax ? salon.fax : 'Fax non disponible'}
            </li>
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">language</span>
              <a href={salon.website ? salon.website : '#'} className="underline">
                {salon.website ? 'Ouvrir la page web' : 'Site web non disponible'}
              </a>
            </li>
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">facebook</span>
              <a href={salon.facebook ? salon.facebook : '#'} className="underline">
                {salon.facebook ? 'Ouvrir la page Facebook' : 'Page Facebook non disponible'}
              </a>
            </li>
            <li className="flex items-center">
              <span className="material-icons text-gray-500 mr-2">instagram</span>
              <a href={salon.instagram ? salon.instagram : '#'} className="underline">
                {salon.instagram ? 'Ouvrir la page Instagram' : 'Page Instagram non disponible'}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SalonAdresseMap;
