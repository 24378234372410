import React from 'react';

const Testimonial = () => {
  return (
    <div className="flex flex-col items-center justify-center py-16 bg-white">
      <h1 className="text-4xl font-semibold text-center text-gray-800">
        Les professionnels du secteur de la beauté et du bien-être nous font confiance.
        <span className="block">Pourquoi pas vous ?</span>
      </h1>

      <div className="max-w-4xl mt-8 text-center">
        <p className="text-gray-600">
          Pour moi, la réservation en ligne par Best Salon fait part de mon service client exceptionnel. 
          Le flexibilité que j'offre à mes clients permet à mon personnel et moi-même une gestion de 
          temps très efficiente. Par ailleurs je suis très satisfait du support locale de Best Salon qui est 
          très réactive et efficace.
        </p>
      </div>

      <div className="flex items-center mt-8">
        <img
          src="https://via.placeholder.com/50" // Remplacer par votre image
          alt="Steff's Barber Shop"
          className="w-12 h-12 rounded-full"
        />
        <div className="ml-4 text-left">
          <p className="text-gray-800 font-semibold">Steff Kinnen</p>
          <p className="text-gray-500">Coiffure Steff</p>
        </div>
      </div>

      {/* Indicators */}
      <div className="flex mt-6 space-x-2">
        <span className="block w-3 h-3 bg-orange-400 rounded-full"></span>
        <span className="block w-3 h-3 bg-gray-300 rounded-full"></span>
        <span className="block w-3 h-3 bg-gray-300 rounded-full"></span>
        <span className="block w-3 h-3 bg-gray-300 rounded-full"></span>
      </div>
    </div>
  );
};

export default Testimonial;
