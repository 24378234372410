import { toast } from 'react-toastify';

export const handleRegisterErrors = (error) => {
    if (error.response) {
        const status = error.response.status;
        const message = error.response.data.message || 'Une erreur est survenue. Veuillez réessayer.';

        switch (status) {
            case 400:
                toast.error(message, {
                    position: "top-right",
                });
                break;
            case 401:
                toast.error(message, {
                    position: "top-right",
                });
                break;
            case 422:
                toast.error('Cet email est déjà enregistré et vérifié.', {
                    position: "top-right",
                });
                break;
            default:
                toast.error('Une erreur est survenue. Veuillez réessayer.', {
                    position: "top-right",
                });
                break;
        }
    } else {
        toast.error('Une erreur est survenue. Veuillez réessayer.', {
            position: "top-right",
        });
    }
};
