import React from 'react';

const CommentSalon = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-10">
      {/* Title */}
      <h2 className="text-2xl font-semibold text-gray-700 mb-6">
        <span className="relative">
          <span className="border-b-4 border-orange-400">Commentaires</span>
        </span>
      </h2>

      {/* Add Comment Link */}
      <div className="mb-4">
        <a href="#" className="text-teal-500 flex items-center">
          <span className="material-icons mr-2">add</span> Écrire un commentaire
        </a>
      </div>

      {/* Comment Description */}
      <p className="text-gray-600">
        Ce salon est nouveau sur Best Salon et n'a pas encore reçu d'avis. Si vous avez réservé un rendez-vous en ligne dans ce salon, nous vous encourageons à partager votre expérience en laissant un avis et ainsi permettre à d'autres clients d'en savoir plus sur les prestations proposées.
      </p>
    </div>
  );
};

export default CommentSalon;
