import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../helpers/authContext';
import { BASE_URL } from '../helpers/url';
import axios from 'axios';
import { handleVerifyErrors } from '../helpers/verifyConfirmationCode';
import { handleResendVerificationCodeErrors, handleResendVerificationCodeSuccess } from '../helpers/validationResendVerificationCode';

const VerifyConfirmationCode = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [error, setError] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken, setAccessToken, setCurrentUser } = useContext(AuthContext);

  const email = location.state?.email;

  useEffect(() => {
    if (accessToken) navigate('/');
  }, [accessToken, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!email) {
      setError("Aucune session utilisateur active.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/verify-email`, { 
        email: email, // Passer l'email ici
        verification_code: verificationCode 
      });
      setLoading(false);

      // Redirection vers la page de connexion après la validation
      navigate('/login');
    } catch (err) {
      setLoading(false);
      handleVerifyErrors(err);
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    setError('');

    if (!email) {
      setError("Aucune session utilisateur active.");
      setResendLoading(false);
      return;
    }

    try {
      await axios.post(`${BASE_URL}/clients/resendcode`, { email }); // Assurez-vous que cela correspond à l'API
      setResendLoading(false);
      handleResendVerificationCodeSuccess();
    } catch (err) {
      setResendLoading(false);
      handleResendVerificationCodeErrors(err);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100"
    style={{
        backgroundImage: 'url(https://plus.unsplash.com/premium_photo-1664049362632-fa2abdf16064?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Vérifiez votre code</h2>
        <p className="text-center text-gray-600 mb-4">
          Merci de saisir le code de confirmation envoyé à votre adresse e-mail.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="sr-only" htmlFor="verificationCode">Code de vérification</label>
            <div className="relative">
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                placeholder="Code de vérification"
                required
              />
            </div>
          </div>

          {error && <div className="text-red-500 mb-4">{error}</div>}

          <button
            type="submit"
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded w-full"
            disabled={loading}
          >
            {loading ? 'Chargement...' : 'Valider'}
          </button>

          <div className="text-center mt-4">
            <p className="text-gray-600">Pas reçu de code ? 
              <a href="#" onClick={handleResendCode} className="text-orange-500 cursor-pointer">
                Renvoyer le code
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyConfirmationCode;
