import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../helpers/url';
import Map from './Map'; // Assurez-vous que le chemin est correct

const CategoriesList = () => {
  const [salons, setSalons] = useState([]);
  const [servicesBySalon, setServicesBySalon] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSalons = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/salon-list`);
        setSalons(response.data.salons);

        // Récupérez les services pour chaque salon
        const servicesPromises = response.data.salons.map(async (salon) => {
          const serviceIds = salon.services; // Liste des IDs de services
          const serviceResponses = await Promise.all(
            serviceIds.map((id) => axios.get(`${BASE_URL}/service/${id}`))
          );
          return {
            salonId: salon._id,
            services: serviceResponses.map((res) => res.data.name), // Stocke les noms des services
          };
        });

        const servicesResults = await Promise.all(servicesPromises);
        const servicesMap = servicesResults.reduce((acc, result) => {
          acc[result.salonId] = result.services;
          return acc;
        }, {});

        setServicesBySalon(servicesMap); // Mettre à jour les services dans l'état
      } catch (error) {
        console.error('Erreur lors de la récupération des salons:', error);
      }
    };

    fetchSalons();
  }, []);

  const handleSalonClick = (salon) => {
    navigate('/details-categories', { state: { salonId: salon._id } });
  };

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Section Liste des salons */}
      <div className="w-full lg:w-1/2 pr-4">
        <div className="categories-list">
          {salons.map((salon, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row items-start p-4 rounded-lg shadow-md bg-white mb-6 hover:shadow-lg transition duration-300 cursor-pointer"
              onClick={() => handleSalonClick(salon)}
            >
              {/* Section Image */}
              <div className="w-full lg:w-1/3 relative">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src={salon.photo ? salon.photo : 'https://plus.unsplash.com/premium_photo-1661964416567-6bc0085053d4?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                  alt={salon.name}
                />
                <div className="absolute top-2 left-2 bg-black text-white text-sm px-2 py-1 rounded-md">
                  Nouveau
                </div>
                <div className="absolute top-2 right-2 text-red-500">
                  <i className="fas fa-heart"></i>
                </div>
              </div>

              {/* Section Détails */}
              <div className="w-full lg:w-2/3 lg:pl-6">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-2xl font-semibold">{salon.name}</h3>
                  <div className="flex items-center">
                    <span className="text-yellow-400 flex space-x-1">
                      {[...Array(5)].map((_, idx) => (
                        <i key={idx} className="fas fa-star"></i>
                      ))}
                    </span>
                    <span className="ml-2 text-sm text-gray-500">1</span>
                  </div>
                </div>

                <p className="text-sm text-gray-600 mb-2">
                  {salon.address ? salon.address : 'Adresse non disponible'}
                </p>

                <p className="text-gray-600 mb-4">
                  {salon.description ? salon.description : 'Description non disponible'}
                </p>

                {/* Liste des services */}
                <ul className="text-gray-600 mb-2">
                  {servicesBySalon[salon._id] ? (
                    servicesBySalon[salon._id].map((service, idx) => (
                      <li key={idx}>{service}</li>
                    ))
                  ) : (
                    <li>Chargement des services...</li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section de la carte */}
      <div className="w-full lg:w-1/2 pl-4">
        <Map /> {/* Intégrez le composant Map ici */}
      </div>
    </div>
  );
};

export default CategoriesList;
