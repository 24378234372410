import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../helpers/authContext';
import { BASE_URL } from '../helpers/url';

const AccountSetting = () => {
    const { accessToken } = useContext(AuthContext); // Récupérer le token d'accès depuis le contexte
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        gender: '',
        password: '',
        new_password: '',
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    // Charger les données actuelles de l'utilisateur
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/profile`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Envoyer le token d'accès dans l'en-tête
                    },
                });
                // Remplir les champs avec les données récupérées
                setFormData({
                    first_name: response.data.first_name || '',
                    last_name: response.data.last_name || '',
                    email: response.data.email || '',
                    phone: response.data.phone || '',
                    gender: response.data.gender || '',
                    password: '', // Réinitialiser le mot de passe pour la saisie
                    new_password: '', // Réinitialiser le nouveau mot de passe pour la saisie
                });
            } catch (error) {
                console.error('Erreur lors du chargement des données utilisateur:', error);
            }
        };

        fetchUserData();
    }, [accessToken]); // Dépendance sur le token d'accès

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        // Ajouter toutes les données du formulaire
        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                formDataToSend.append(key, formData[key]);
            }
        });

        try {
            const response = await axios.post(`${BASE_URL}/update-profile`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`, // Envoyer le token d'accès lors de la mise à jour du profil
                },
            });

            // Si la requête est réussie
            setSuccessMessage(response.data.message);
            setErrors({});
        } catch (error) {
            if (error.response && error.response.status === 422) {
                // Erreurs de validation
                setErrors(error.response.data);
            }
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6">Account Settings</h2>

            {successMessage && <div className="mb-4 text-green-500">{successMessage}</div>}

            <form onSubmit={handleSubmit}>
                {/* First Name */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="first_name">First Name</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    {errors.first_name && <div className="text-red-500">{errors.first_name}</div>}
                </div>

                {/* Last Name */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="last_name">Last Name</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    {errors.last_name && <div className="text-red-500">{errors.last_name}</div>}
                </div>

                {/* Email */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                        disabled // Désactiver le champ email car il ne devrait pas être modifié
                    />
                    {errors.email && <div className="text-red-500">{errors.email}</div>}
                </div>

                {/* Phone */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="phone">Phone</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    {errors.phone && <div className="text-red-500">{errors.phone}</div>}
                </div>

                {/* Gender */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="gender">Gender</label>
                    <select
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    {errors.gender && <div className="text-red-500">{errors.gender}</div>}
                </div>

                {/* Password */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    {errors.password && <div className="text-red-500">{errors.password}</div>}
                </div>

                {/* New Password */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2" htmlFor="new_password">New Password</label>
                    <input
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={formData.new_password}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    {errors.new_password && <div className="text-red-500">{errors.new_password}</div>}
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-600 text-white font-bold py-2 rounded-md hover:bg-blue-700"
                >
                    Update Profile
                </button>
            </form>
        </div>
    );
};

export default AccountSetting;
