import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from '../helpers/url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    // Récupérer l'email passé via navigate
    const email = location.state?.email;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        
        // Validation locale des mots de passe avant d'envoyer la requête
        if (password !== confirmPassword) {
            setErrors({ confirmPassword: "Les mots de passe ne correspondent pas." });
            return;
        }

        setLoading(true);
        
        // Préparation des données à envoyer à l'API
        const data = { 
            email, 
            password, 
            password_confirmation: confirmPassword // Laravel attend ce champ pour la confirmation
        };

        try {
            // Appel de l'API pour changer le mot de passe
            const response = await axios.post(`${BASE_URL}/change-password`, data); 
            
            toast.success('Mot de passe changé avec succès!', { position: "top-right" });
            
            // Réinitialisation des champs
            setPassword('');
            setConfirmPassword('');
            
            // Redirection vers la page de connexion
            navigate('/login');  
        } catch (error) {
            setLoading(false);
            
            // Gestion des erreurs spécifiques de validation
            if (error.response && error.response.data) {
                const validationErrors = error.response.data;
                
                // S'il s'agit d'erreurs de validation renvoyées par Laravel
                if (validationErrors) {
                    setErrors(validationErrors);
                    toast.error("Erreur de validation.", { position: "top-right" });
                }
            } else {
                toast.error("Une erreur est survenue. Veuillez réessayer.", { position: "top-right" });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div 
            className="flex justify-center items-center h-screen" 
            style={{
                backgroundImage: 'url(https://plus.unsplash.com/premium_photo-1664049362632-fa2abdf16064?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                <h2 className="text-2xl font-bold text-center mb-6">Changer votre mot de passe</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="sr-only" htmlFor="password">Nouveau mot de passe</label>
                        <div className="relative">
                            <input
                                type="password"
                                id="password"
                                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Nouveau mot de passe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {errors.password && <div className="text-red-500">{errors.password[0]}</div>}
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="sr-only" htmlFor="confirmPassword">Confirmer le mot de passe</label>
                        <div className="relative">
                            <input
                                type="password"
                                id="confirmPassword"
                                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Confirmer le mot de passe"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            {errors.confirmPassword && <div className="text-red-500">{errors.confirmPassword}</div>}
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded w-full"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Changer le mot de passe'}
                    </button>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
};

export default ChangePassword;
