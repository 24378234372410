// pages/Register.jsx
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../helpers/authContext';
import { BASE_URL } from '../helpers/url';
import { handleRegisterErrors } from '../helpers/registervalidation';
import { renderValidationErrors } from '../helpers/validation';
import Spinner from '../components/Spinner';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [title, setTitle] = useState('');
  const [role] = useState('client'); // Définir le rôle par défaut en tant que 'client'
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { accessToken } = useContext(AuthContext) || {};

  useEffect(() => {
    if (accessToken) {
      navigate('/');
    }
  }, [accessToken, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);

    // Vérifier la correspondance des mots de passe
    if (password !== confirmPassword) {
      setErrors({ confirmPassword: ['Les mots de passe ne correspondent pas.'] });
      setLoading(false);
      return;
    }

    const data = {
      role, // Ajout du rôle aux données
      title,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      phone,
      gender,
    };

    try {
      const response = await axios.post(`${BASE_URL}/register`, data);
      setLoading(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPhone('');
      setGender('');
      setTitle('');
      navigate('/verification-code', { state: { email: data.email } });
    } catch (error) {
      setLoading(false);
      handleRegisterErrors(error);
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);
      }
      console.error(error);
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(https://plus.unsplash.com/premium_photo-1664049362632-fa2abdf16064?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-6">Créer un compte Best Salons</h2>
        <p className="text-center text-gray-600 mb-4">Merci de créer un compte pour continuer</p>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="sr-only" htmlFor="title">Titre</label>
            <select
              id="title"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            >
              <option value="">Sélectionnez un titre</option>
              <option value="Monsieur">Monsieur</option>
              <option value="Madame">Madame</option>
            </select>
            {renderValidationErrors(errors, 'title')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="first-name">Prénom</label>
            <input
              type="text"
              id="first-name"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              minLength="2"
              required
            />
            {renderValidationErrors(errors, 'first_name')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="last-name">Nom</label>
            <input
              type="text"
              id="last-name"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              minLength="2"
              required
            />
            {renderValidationErrors(errors, 'last_name')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {renderValidationErrors(errors, 'email')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="phone">Téléphone</label>
            <input
              type="text"
              id="phone"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            {renderValidationErrors(errors, 'phone')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="gender">Genre</label>
            <select
              id="gender"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Sélectionnez un genre</option>
              <option value="male">Homme</option>
              <option value="female">Femme</option>
              <option value="other">Autre</option>
            </select>
            {renderValidationErrors(errors, 'gender')}
          </div>

          <div className="mb-4">
            <label className="sr-only" htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {renderValidationErrors(errors, 'password')}
          </div>

          <div className="mb-6">
            <label className="sr-only" htmlFor="confirm-password">Confirmez le mot de passe</label>
            <input
              type="password"
              id="confirm-password"
              className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
              placeholder="Confirmez le mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {renderValidationErrors(errors, 'confirmPassword')}
          </div>

          <button
            type="submit"
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
            disabled={loading}
          >
            {loading ? <Spinner /> : 'S\'inscrire'}
          </button>
        </form>

        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Déjà inscrit ?{' '}
            <button
              className="text-teal-500 hover:text-teal-600"
              onClick={handleLoginClick}
            >
              Connectez-vous ici
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
