export const handleLoginErrors = (error, setErrors, toast) => {
    if (error.response) {
        const { status, data } = error.response;

        // Gestion des erreurs de validation (status 422 ou autre)
        if (status === 422) {
            setErrors(data.errors || {});
            if (data.errors.email) {
                toast.error(data.errors.email[0], { position: "top-right" });
            }
            if (data.errors.password) {
                toast.error(data.errors.password[0], { position: "top-right" });
            }
        } else {
            // Pour tout autre statut d'erreur
            toast.error(data.message || 'Une erreur est survenue', { position: "top-right" });
        }
    } else {
        // Si l'erreur n'a pas de réponse (erreur réseau ou autre)
        toast.error('Une erreur de réseau est survenue', { position: "top-right" });
    }
};
