import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaStar, FaCartPlus } from 'react-icons/fa';
import axios from 'axios';
import { BASE_URL } from '../helpers/url';
import { useLocation } from 'react-router-dom'; // Importer useLocation

const DetailsPhotosCategories = () => {
  const [salon, setSalon] = useState(null); // État pour stocker les détails du salon
  const [error, setError] = useState(null); // État pour gérer les erreurs
  const location = useLocation(); // Utiliser useLocation pour récupérer l'état
  const salonId = location.state?.salonId; // Récupérer l'ID du salon depuis l'état

  useEffect(() => {
    const fetchSalonDetails = async () => {
      if (!salonId) return; // Ne pas continuer si salonId n'est pas défini
      try {
        const response = await axios.get(`${BASE_URL}/salon-details/${salonId}`);
        console.log("response.data", response.data);
        setSalon(response.data.salon); // Accéder à salon.salon
      } catch (error) {
        console.error("Erreur lors de la récupération des détails du salon:", error);
        setError(error);
      }
    };
  
    fetchSalonDetails();
  }, [salonId]); // Ajouter salonId comme dépendance

  if (error) return <div>Une erreur s'est produite : {error.message}</div>; // Affiche une erreur si elle se produit
  if (!salon) return <div>Loading...</div>; // Affiche un message de chargement si les données ne sont pas encore disponibles

  console.log("Salon details:", salon);

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      {/* SalonCard Details */}
      <div className="flex justify-between items-start border-b-2 border-orange-300 py-4 mb-8 text-sm">
        <div>
          {/* Salon name */}
          <h1 className="text-2xl font-bold text-gray-800">{salon.name}</h1> {/* Utilise le nom du salon récupéré */}

          {/* Subtext/Description */}
          <p className="text-base text-gray-500 mt-2">
            {salon.description} {/* Utilise la description du salon récupéré */}
          </p>

          {/* Address with icon */}
          <div className="flex items-center text-gray-500 mt-3">
            <FaMapMarkerAlt className="mr-2" />
            <span>{salon.address}</span> {/* Utilise l'adresse récupérée */}
          </div>
        </div>

        {/* Appointment button et Rating section */}
        <div className="flex flex-col justify-between items-start">
          <button className="bg-gray-500 text-white px-4 py-2 rounded flex items-center">
            <FaCartPlus className="mr-2" />
            Prendre rendez-vous
          </button>

          {/* Rating section alignée */}
          <div className="flex items-center space-x-1 mt-4">
            {/* 5 Stars */}
            {[...Array(5)].map((_, index) => (
              <FaStar key={index} className="text-yellow-400" />
            ))}
            {/* Review count */}
            <span className="text-gray-500 text-sm ml-1">1</span>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Large Image */}
        <div className="col-span-1 md:col-span-2">
          <img
            src="https://plus.unsplash.com/premium_photo-1661645807231-2635324e8833?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Main Image"
            className="w-full h-auto object-cover rounded-md"
          />
        </div>

        {/* Small Images Grid */}
        <div className="grid grid-cols-3 md:grid-cols-6 gap-2 col-span-1 md:col-span-2">
          {/* Ajoutez vos images ici */}
          {/* ... */}
        </div>
      </div>
    </div>
  );
};

export default DetailsPhotosCategories;
