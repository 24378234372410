import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePageOne from "./pages/HomePageOne";
import Category from "./pages/Category";
import DetailsCategories from "./pages/DetailsCategories";
import Reservation from "./pages/Reservation";
import Login from "./pages/Login";
import Register from "./pages/Register";
import VerifyConfirmationCode from "./pages/VerifyConfirmationCode";
import { AuthProvider } from "./helpers/authContext"; // Assurez-vous que le chemin est correct
import ProtectedRoute from './helpers/ProtectedRoute'; // Importez le composant de route protégée
import ResendVerificationCode from "./pages/ResendVerificationCode";
import ReverifyConfirmationCode from "./pages/ReverifyConfirmationCode";
import ChangePassword from "./pages/ChangePassword";
import UpdateProfile from "./pages/UpdateProfile";
import Profile from "./pages/Profile";

function App() {
  return (
    <AuthProvider> {/* Enveloppez vos routes ici */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePageOne />} />

          {/* Pages protégées (login, register) */}
          <Route
            exact
            path="/login"
            element={<ProtectedRoute element={<Login />} redirectTo="/" />}
          />
          <Route
            exact
            path="/register"
            element={<ProtectedRoute element={<Register />} redirectTo="/" />}
          />

          <Route
            exact
            path="/change-password"
            element={<ProtectedRoute element={<ChangePassword />} redirectTo="/" />}
          />

          <Route
            exact
            path="/verification-code"
            element={<ProtectedRoute element={<VerifyConfirmationCode />} redirectTo="/" />}
          />

          <Route
            exact
            path="/reverification-code"
            element={<ProtectedRoute element={<ReverifyConfirmationCode />} redirectTo="/" />}
          />

          <Route
            exact
            path="/resend-code"
            element={<ProtectedRoute element={<ResendVerificationCode />} redirectTo="/" />}
          />


          {/* Autres routes */}
          <Route exact path="/categories" element={<Category />} />
          <Route exact path="/details-categories" element={<DetailsCategories />} />
          <Route exact path="/reservation" element={<Reservation />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/update-profile" element={<UpdateProfile />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
