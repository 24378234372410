import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameMonth, isSameDay, addDays } from 'date-fns';
import 'tailwindcss/tailwind.css';
import { BASE_URL } from '../helpers/url';
import { useAuth } from '../helpers/authContext';

const AppointmentCalendar = () => {
  const location = useLocation();
  const { services, salonId } = location.state || {};
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null); // Nouvel état pour le créneau sélectionné

  // Utilisation du contexte pour récupérer l'accessToken
  const { accessToken } = useAuth();

  const serviceId = services && services[0]?.id;

  // Récupération des données du service
  useEffect(() => {
    const fetchServiceData = async () => {
      if (serviceId) {
        try {
          const serviceResponse = await axios.get(`${BASE_URL}/service/${serviceId}`);
          setServiceData(serviceResponse.data);
        } catch (error) {
          console.error("Erreur lors de la récupération des données du service :", error);
        }
      }
    };

    fetchServiceData();
  }, [serviceId]);

  // Récupération des créneaux horaires disponibles
  useEffect(() => {
    const fetchAvailableTimeSlots = async () => {
      if (selectedDate && serviceData && accessToken) {
        try {
          const dateString = format(selectedDate, 'yyyy-MM-dd');

          const response = await axios.get(`${BASE_URL}/salons/${salonId}/timeslots/available`, {
            params: {
              date: dateString,
              duration: serviceData.duration
            },
            headers: {
              Authorization: `Bearer ${accessToken}`, // Utiliser le token correct
              'Content-Type': 'application/json',
            },
          });

          setTimeSlots(response.data.available_slots);
        } catch (error) {
          console.error("Erreur lors de la récupération des créneaux horaires disponibles :", error);
        }
      }
    };

    fetchAvailableTimeSlots();
  }, [selectedDate, salonId, serviceData, accessToken]);

  // Affichage de l'en-tête du calendrier
  const renderHeader = () => (
    <div className="flex justify-between items-center py-2">
      <button onClick={prevMonth} className="text-xl font-bold">{'<'}</button>
      <div className="text-lg font-semibold">{format(currentMonth, 'MMM yyyy')}</div>
      <button onClick={nextMonth} className="text-xl font-bold">{'>'}</button>
    </div>
  );

  // Affichage des jours de la semaine
  const renderDays = () => {
    const days = [];
    const startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="text-center font-semibold" key={i}>
          {format(addDays(startDate, i), 'EE')}
        </div>
      );
    }
    return <div className="grid grid-cols-7">{days}</div>;
  };

  // Affichage des cellules du calendrier (jours du mois)
  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    const today = new Date();

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, 'd');
        const cloneDay = day;

        days.push(
          <div
            className={`text-center p-2 cursor-pointer ${
              !isSameMonth(day, monthStart)
                ? 'text-gray-400'
                : isSameDay(day, selectedDate)
                ? 'bg-yellow-300'
                : !selectedDate && isSameDay(day, today)
                ? 'bg-yellow-200'
                : ''
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span>{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="grid grid-cols-7" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleTimeSlotSelect = (slot) => {
    setSelectedTimeSlot(slot);
  };

  // Fonction pour envoyer la requête de réservation
  const handleReservation = async () => {
    if (!selectedDate || !selectedTimeSlot || !salonId || !services) return;
  
    // Log des valeurs dans la console
    const formattedDateTime = `${format(selectedDate, 'yyyy-MM-dd')}T${selectedTimeSlot.start_time}`;
    console.log('Salon ID:', salonId);
    console.log('Services:', services.map(service => service.id));
    console.log('Date et Heure:', formattedDateTime);
  
    try {
      const response = await axios.post(
        `${BASE_URL}/reservations/store`,
        {
          salon_id: salonId,
          services: services.map((service) => service.id),
          date_time: formattedDateTime,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Réservation réussie:', response.data);
      alert('Votre réservation a été créée avec succès.');
    } catch (error) {
      console.error('Erreur lors de la création de la réservation:', error);
      alert('Une erreur est survenue lors de la réservation.');
    }
  };
  

  const renderTimeSlots = () => (
    <div>
      {timeSlots.length > 0 ? (
        timeSlots.map((slot) => (
          <button
            key={slot}
            onClick={() => handleTimeSlotSelect(slot)}
            className={`bg-green-100 text-green-700 px-4 py-2 rounded-lg font-medium hover:bg-green-200 m-4 ${
              selectedTimeSlot === slot ? 'bg-green-200' : ''
            }`}
          >
            {slot.start_time} - {slot.end_time}
          </button>
        ))
      ) : (
        <p>Aucun créneau disponible pour cette date.</p>
      )}
    </div>
  );

  return (
    <div className="flex space-x-6 p-6 mx-auto max-w-6xl bg-white rounded-lg shadow-lg">
      <div className="w-1/2 bg-white shadow-lg p-6 rounded-lg">
        <h3 className="text-xl font-semibold mb-4 text-green-700">Vérifier votre sélection</h3>
        {serviceData ? (
          <div className="border p-4 mb-4">
            <div className="text-lg">{serviceData.name}</div>
            <div className="flex justify-between mt-4">
              <div className="text-sm">{serviceData.duration} minutes</div>
              <div className="font-bold">Total: {serviceData.price} €</div>
            </div>
          </div>
        ) : (
          <div className="text-gray-500">Chargement des informations du service...</div>
        )}
        <p className="text-sm text-gray-500">
          Note: Veuillez noter que tous les prix doivent être considérés comme indicatifs et peuvent changer en fonction du type, de la durée et de la complexité du service qui vous est fourni sur place.
        </p>
      </div>

      <div className="w-1/2 bg-white shadow-lg p-6 rounded-lg">
        <h2 className="text-center text-xl font-bold mb-4">
          Sélectionnez une date et une heure
        </h2>
        {renderHeader()}
        {renderDays()}
        {renderCells()}

        {selectedDate && (
          <div className="mt-6">
            <h3 className="text-lg font-bold text-center mb-4">
              Créneaux horaires disponibles pour le {format(selectedDate, 'dd/MM/yyyy')}
            </h3>
            {renderTimeSlots()}
          </div>
        )}

        {/* Affichage du bouton Valider réservation après la sélection d'un créneau */}
        {selectedTimeSlot && (
          <div className="flex justify-center mt-6">
            <button
              onClick={handleReservation}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg font-bold hover:bg-blue-600"
            >
              Valider réservation
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppointmentCalendar;
