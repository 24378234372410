import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className="bg-gray-100 flex flex-col items-center justify-center" style={{ minHeight: '250px' }}>
      <div className="text-center mb-4">
        {/* Title */}
        <span className="text-lg font-medium text-gray-700">
          NOUS <span className="text-red-400">❤️</span> LES NOUVEAUX AMIS
        </span>
      </div>

      {/* Social Icons */}
      <div className="flex justify-center space-x-8">
        <a href="https://facebook.com" className="text-gray-600 hover:text-gray-900">
          <FaFacebookF className="w-8 h-8" />
        </a>
        <a href="https://instagram.com" className="text-gray-600 hover:text-gray-900">
          <FaInstagram className="w-8 h-8" />
        </a>
        <a href="https://youtube.com" className="text-gray-600 hover:text-gray-900">
          <FaYoutube className="w-8 h-8" />
        </a>
        <a href="https://linkedin.com" className="text-gray-600 hover:text-gray-900">
          <FaLinkedinIn className="w-8 h-8" />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
