import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../helpers/url';

const Modal = ({ isOpen, onClose, service, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-[999]">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{service.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>

        <p className="text-gray-600 mb-4">Description</p>
        <p className="text-sm text-gray-500 mb-6">
          Toutes les prestations incluent shampooing, soin et produits de finition.
        </p>

        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <p className="text-gray-600">Prix : {service.price} €</p>
            <button
              onClick={() => {
                onConfirm(service); // Confirmer le service
                onClose();
              }}
              className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded"
            >
              Confirmer
            </button>
          </div>
        </div>

        <p className="text-xs text-gray-500 mt-6">
          Note: Veuillez noter que tous les prix sont indicatifs et peuvent varier en fonction de la durée et de la complexité du service qui vous est fourni sur place.
        </p>
      </div>
    </div>
  );
};

const InfosPresentationServices = () => {
  const navigate = useNavigate(); // Initialisation de navigate
  const location = useLocation();
  const { salonId } = location.state || {};

  const [salon, setSalon] = useState(null);
  const [services, setServices] = useState({});
  const [error, setError] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const fetchSalonDetails = async () => {
      if (!salonId) return;
      try {
        const response = await axios.get(`${BASE_URL}/salon-details/${salonId}`);
        setSalon(response.data.salon);

        const serviceIds = response.data.salon.services;
        const servicesData = {};

        for (const id of serviceIds) {
          const serviceResponse = await axios.get(`${BASE_URL}/service/${id}`);
          const service = serviceResponse.data;
          const category = service.category || "";

          if (!servicesData[category]) {
            servicesData[category] = [];
          }
          servicesData[category].push(service);
        }

        setServices(servicesData);

      } catch (error) {
        console.error("Erreur lors de la récupération des détails du salon:", error);
        setError(error);
      }
    };

    fetchSalonDetails();
  }, [salonId]);

  const handleServiceSelection = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleConfirmService = (service) => {
    setSelectedServices((prevServices) => [...prevServices, { id: service._id, ...service }]);
  };
  

  const removeService = (index) => {
    setSelectedServices((prevServices) =>
      prevServices.filter((_, i) => i !== index)
    );
  };

  const handleContinueClick = () => {
    const currentDate = new Date();
    console.log("Date actuelle :", currentDate);
    console.log("Services sélectionnés:", selectedServices);

    navigate('/reservation', { state: { services: selectedServices, date: currentDate, salonId: salonId } });
  };

  return (
    <div className="w-full max-w-5xl mx-auto p-4">
      <div className="flex flex-row">
        <div className="w-full">
          <h2 className="text-xl font-bold mb-4">Prestations</h2>
          <ul className="space-y-4">
            {Object.keys(services).map((category, index) => (
              <li key={index}>
                <h3 className="font-semibold text-lg mb-4">{category}</h3>
                <ul className="space-y-4">
                  {services[category].map((service) => (
                    <li key={service._id} className="flex justify-between items-center border-b pb-4">
                      <div>
                        <span className="font-bold text-md">{service.name}</span>
                        <div className="text-gray-500 text-sm">À partir de {service.price} €</div>
                      </div>
                      <button
                        onClick={() => handleServiceSelection(service)}
                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                      >
                        Sélectionner
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        service={selectedService}
        onConfirm={handleConfirmService}
      />

      {selectedServices.length > 0 && (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 p-4 z-[9999] shadow-md">
          <div className="max-w-5xl mx-auto flex items-center justify-between">
            <div className="flex flex-wrap space-x-4">
              <h3 className="text-lg font-semibold">{selectedServices.length} Service(s) sélectionné(s)</h3>
              {selectedServices.map((service, index) => (
                <div key={index} className="flex items-center space-x-2 bg-gray-200 p-2 rounded-lg">
                  <span>{service.name}</span>
                  <button
                    onClick={() => removeService(index)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <button
              className="bg-gradient-to-r from-orange-400 to-orange-600 text-white px-6 py-2 rounded"
              onClick={handleContinueClick}
            >
              Continuer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfosPresentationServices;
