import React from 'react';

const PopularCities = () => {
  return (
    <div className="bg-gray-100 py-16 px-4">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-8">
        Découvrez nos villes populaires
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 text-center">
        <p className="text-gray-600">Bruxelles</p>
        <p className="text-gray-600">Ixelles</p>
        <p className="text-gray-600">Anvers</p>
        <p className="text-gray-600">Malines</p>
        <p className="text-gray-600">Louvain</p>
        <p className="text-gray-600">Hasselt</p>
        <p className="text-gray-600">Aalst</p>
        <p className="text-gray-600">Liège</p>
        <p className="text-gray-600">Namur</p>
        <p className="text-gray-600">Charleroi</p>
        <p className="text-gray-600">Mons</p>
        <p className="text-gray-600">La Louvière</p>
        <p className="text-gray-600">Brugge</p>
        <p className="text-gray-600">Courtrai</p>
        <p className="text-gray-600">Gand</p>
      </div>
    </div>
  );
};

export default PopularCities;
