import React from 'react';
import TopHeader from "../components/TopHeader";
import Footer from '../components/Footer';
import DetailsPhotosCategories from '../components/DetailsPhotosCategories';
import InfosPresentationServices from '../components/InfosPresentationServices';
import SalonAdresseMap from '../components/SalonAdresseMap';
import CommentSalon from '../components/CommentSalon';



export default function Category() {
  return (
    <>
      {/* TopHeader */}
      <TopHeader />

      {/* DetailsPhotosCategories */}
      <DetailsPhotosCategories />

       {/* InfosPresentationServices */}
       <InfosPresentationServices />

       {/* SalonAdresseMap */}
       <SalonAdresseMap />

        {/* CommentSalon */}
        <CommentSalon />

      {/* Footer */}
      <Footer />
    </>
  );
}
