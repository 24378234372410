import React from 'react';
import TopHeader from "../components/TopHeader";
import Footer from '../components/Footer';
import AccountSetting from '../components/AccountSetting';


export default function UpdateProfile() {
    return (
        <>
            {/* TopHeader */}
            <TopHeader />

            {/* AccountSetting */}
            <AccountSetting />

            {/* Footer */}
            <Footer />
        </>
    );
}
