import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../helpers/url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResendVerificationCode = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleResendCode = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${BASE_URL}/resend-verification-code`, { email });
            if (response.status === 200) {
                toast.success(response.data.message, { position: "top-right" });
                setEmail(''); // Réinitialiser le champ email

                // Redirection vers la page de vérification du code avec l'email dans l'état
                navigate('/reverification-code', { state: { email } });
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 422) {
                    const errors = data;
                    for (const key in errors) {
                        toast.error(errors[key][0], { position: "top-right" });
                    }
                } else {
                    toast.error('Une erreur est survenue. Veuillez réessayer.', { position: "top-right" });
                }
            } else {
                toast.error('Une erreur est survenue. Veuillez vérifier votre connexion.', { position: "top-right" });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div 
            className="flex justify-center items-center h-screen" 
            style={{
                backgroundImage: 'url(https://plus.unsplash.com/premium_photo-1664049362632-fa2abdf16064?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                <h2 className="text-2xl font-bold text-center mb-6">Renvoyer le code de vérification</h2>
                <p className="text-center text-gray-600 mb-4">
                    Merci de saisir votre adresse email pour recevoir un nouveau code de vérification.
                </p>
                <form onSubmit={handleResendCode}>
                    <div className="mb-4">
                        <label className="sr-only" htmlFor="email">Email</label>
                        <div className="relative">
                            <input
                                type="email"
                                id="email"
                                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded w-full"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Renvoyer le code'}
                    </button>
                </form>

                <div className="text-center mt-4">
                    <p className="text-gray-600">Déjà vérifié ? <a href="#" onClick={() => navigate('/login')} className="text-orange-500">Connectez-vous !</a></p>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ResendVerificationCode;
