import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './authContext';

const ProtectedRoute = ({ element, redirectTo }) => {
    const { accessToken } = useContext(AuthContext);

    // Vérifier si l'utilisateur est connecté et rediriger si nécessaire
    if (accessToken) {
        // Si l'utilisateur est déjà connecté, rediriger vers la page souhaitée
        return <Navigate to={redirectTo} />;
    }

    // Si l'utilisateur n'est pas connecté, rendre la page de connexion ou d'inscription
    return element;
};

export default ProtectedRoute;
