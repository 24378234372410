import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../helpers/url';
import { handleLoginErrors } from '../helpers/loginvalidation';
import { AuthContext } from '../helpers/authContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { accessToken, setAccessToken, setCurrentUser } = useContext(AuthContext);

    useEffect(() => {
        if (accessToken) navigate('/');
    }, [accessToken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);
        const data = { email, password };

        try {
            const response = await axios.post(`${BASE_URL}/login`, data);
            if (response.data.error) {
                toast.error(response.data.error, { position: "top-right" });
            } else {
                localStorage.setItem('accessToken', JSON.stringify(response.data.access_token));
                setAccessToken(response.data.access_token);
                setCurrentUser(response.data.user);
                setEmail('');
                setPassword('');
                navigate('/');
                toast.success('Connexion réussie!', { position: "top-right" });
            }
        } catch (error) {
            handleLoginErrors(error, setErrors, toast);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour rediriger vers ResendVerificationCode
    const handleForgotPassword = () => {
        navigate('/resend-code'); // Redirige vers la page de renvoi de code
    };


    return (
        <div
            className="flex justify-center items-center h-screen"
            style={{
                backgroundImage: 'url(https://plus.unsplash.com/premium_photo-1664049362632-fa2abdf16064?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                <h2 className="text-2xl font-bold text-center mb-6">Se connecter sur Best Salons</h2>
                <p className="text-center text-gray-600 mb-4">
                    Merci de créer un compte ou de vous connecter afin de continuer
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="sr-only" htmlFor="email">Email</label>
                        <div className="relative">
                            <input
                                type="email"
                                id="email"
                                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="text-red-500">{errors.email}</div>}
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="sr-only" htmlFor="password">Mot de passe</label>
                        <div className="relative">
                            <input
                                type="password"
                                id="password"
                                className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="text-red-500">{errors.password}</div>}
                        </div>
                    </div>

                    <div className="text-right mb-6">
                        <a href="#" className="text-red-500" onClick={handleForgotPassword}>
                            Mot de passe oublié ?
                        </a>
                    </div>

                    <button
                        type="submit"
                        className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded w-full"
                        disabled={loading}
                    >
                        {loading ? 'Chargement...' : 'Connexion'}
                    </button>
                </form>

                <div className="text-center mt-4">
                    <p className="text-gray-600">Pas encore de compte ? <a href="#" onClick={() => navigate('/register')} className="text-orange-500">Inscrivez-vous !</a></p>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login;
