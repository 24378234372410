import React from 'react';
import TopHeader from "../components/TopHeader";
import Footer from '../components/Footer';
import DetailsProfile from '../components/DetailsProfile';


export default function Profile() {
    return (
        <>
            {/* TopHeader */}
            <TopHeader />

            {/* AccountSetting */}
            <DetailsProfile />

            {/* Footer */}
            <Footer />
        </>
    );
}
