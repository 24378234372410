import React from 'react';
import TopHeader from "../components/TopHeader";
import Footer from '../components/Footer';
import SearchByFilter from '../components/SearchByFilter';
import CategoriesList from '../components/CategoriesList';


export default function Category() {
  return (
    <>
      {/* TopHeader */}
      <TopHeader />

      {/* SearchByFilter */}
      <SearchByFilter />

       {/* CategoriesList */}
       <CategoriesList />

      {/* Footer */}
      <Footer />
    </>
  );
}
